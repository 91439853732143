import React, { useContext, useRef, useState } from "react";
import QrCode from 'react-qr-code'
import { Breadcrumbs, Btn, P } from "../../AbstractElements";
import { dynamicImage } from "../../Services";
import studentContext from "../../Helper/Student";
import { toast } from "react-toastify";
import { extractedCourseName } from "../../utils/courses";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const StudentCard = () => {
  const { studentDetails, currentCourse } = useContext(studentContext);
  const [isLoading, setIsLoading] = useState(false)
  const cardRef = useRef(null)

  const downloadClickHandler = async () => {
    setIsLoading(true)
    const isFacebookBrowser =
      navigator.userAgent.includes("FBAN") ||
      navigator.userAgent.includes("FBAV");
    if (isFacebookBrowser) {
      toast.error('Please download your ID card from any other browser')
      return
    }
    
    try {
      if (!cardRef.current) throw new Error;
      const scale = 2;
  
      const canvas = await html2canvas(cardRef.current, {
        scale: scale,
        backgroundColor: "#FFFFFF",
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
  
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
  
      const pdfWidth = 210;
      const pdfHeight = 297;
  
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = (imgProps.width / scale);
      const imgHeight = (imgProps.height / scale);
  
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const width = imgWidth * ratio;
      const height = imgHeight * ratio;
  
      const xOffset = (pdfWidth - width) / 2;
      const yOffset = (pdfHeight - height) / 2;
  
      pdf.addImage(imgData, "PNG", xOffset, yOffset, width, height);
  
      pdf.save(`${extractedCourseName(currentCourse.name)}-${currentCourse.type.charAt(0).toUpperCase()}-${studentDetails.studentId}.pdf`);
    } catch (error) {
      console.error("Error generating the PDF:", error);
      toast.error("Error downloading the card, Please try again");
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Breadcrumbs mainTitle="Student Card" parent="Dashboard" />
      <div className="removeScrollbar main-card">
        <div className="d-flex align-items-center justify-content-center">
          <Btn attrBtn={{ color: "primary", onClick: downloadClickHandler, className: 'student-card-btn', disabled: isLoading }}>Download</Btn>
        </div>
        <div className="student-card" ref={cardRef}>
          <div className="card-front">
            <img className="img-full student-card-bg" src={dynamicImage("studentCardBackgroundLeft.png")} draggable="false" />
            <div className="id-card-front">
              <div className="card-detail-container">

                <img className="img-full" src={dynamicImage("studentCardFront.png")} draggable="false"
                />
                <P attrPara={{ className: "id-card-id" }} >{extractedCourseName(currentCourse.name)}-{currentCourse.type.charAt(0).toUpperCase()}-{studentDetails.studentId}</P>
                <P attrPara={{ className: "id-card-name" }} >{studentDetails.fullName}</P>
                <P attrPara={{ className: "id-card-contact" }} > {!studentDetails.contactNo.toString().startsWith("0") ? `0${studentDetails.contactNo}` : studentDetails.contactNo}</P>
                <img className="id-card-image" draggable="false"
                  src={studentDetails?.userProfileImage} />
              </div>
            </div>
          </div>
          <div className="card-back">
            <img className="img-full student-card-bg" src={dynamicImage("studentCardBackgroundRight.png")} draggable="false" />
            <div className="id-card-back">
              {studentDetails.studentId && <div className="qr-code-container">
                <div className="qr-code-main">
                  <QrCode
                    size={65}
                    value={`studentId=${String(studentDetails.studentId)}/email=${studentDetails.email}`}
                    viewBox={`0 0 65 65`}
                  />
                </div>
              </div>}
              <img className="img-full" src={dynamicImage("studentCardBack.png")} draggable="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
