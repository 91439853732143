import { useContext, useState } from 'react';
import firebase from 'firebase';
import { Container } from 'reactstrap';
import { Btn, P } from '../../AbstractElements';
import studentContext from '../../Helper/Student'
import { confirmEmail } from '../../Service/Firebase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {setStudentDetails, studentDetails} = useContext(studentContext)

  const CheckMark = () => {
    return (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
      <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
      <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
    </svg>)
  }

  const verifyEmail = async () => {
    let code;
    const urlParams = new URLSearchParams(window.location.search);
    code = urlParams.get("oobCode");
    setLoading(true)
    try {
      await confirmEmail(code)
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        // If the user is logged in, update the student details in the context
      setStudentDetails({
        ...studentDetails,
        emailVerified: true
      })
      setTimeout(() => {
        navigate("/dashboard")
      }, 2000);
      } else {
        // If the user is not logged in, redirect to the login page
        toast.success('Email verified. Please log in.');
        navigate("/login");
      }
    } catch (error) {
      setLoading(false)
      toast.error('The code has been expired')
    }
  }

  return (
    <Container fluid={true} className="p-0 verify-email-container">
      <div className='verify-email shadow-sm shadow-showcase'>
        <P attrPara={{ className: 'f-w-600' }}>Thanks for being a part of Expertizo University</P>
        <CheckMark />
        <P attrPara={{ className: 'f-12' }}>We're happy you're here. Let's get your email address verified:</P>
        <Btn attrBtn={{ color: 'primary', onClick: verifyEmail, disabled: loading }}>Click to Verify Email {loading ? '...' : ''}</Btn>
      </div>
    </Container>
  );
};
export default VerifyEmail;